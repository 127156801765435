@import "core/scss/vars";

.content {
  display: flex;
  flex-direction: column;

  .line {
    margin: 5px 0;
  }

  .bottomLine {
    width: 100%;
    height: 2px;
    background-color: $black-light-3;
    border-radius: 2px;
  }
}