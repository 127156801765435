@import "core/scss/vars";

.input {
  outline: none;
  background-color: $black-light-3;
  border: 1px solid $white;
  border-radius: 15px;
  padding: 20px 25px;
  transition: all .2s;

  //&:hover {
  //  border: 3px solid $white;
  //}

  &:focus {
    border-color: $white;
    box-shadow: inset 0 0 0 1.5px $white;
  }
}