@import "core/scss/vars";

.tooltip {
  background-color: $black-light-2;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 10px;

  .text {
    //color: $;
  }
}