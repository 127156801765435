@import "core/scss/vars";

.button {
  background-color: $green;
  color: $black-light-1;
  padding: 10px 15px;
  border-radius: 10px;

  transition: all .2s;

  &:hover {
    background-color: $white;
  }
}