@import "core/scss/vars";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  background-color: $black-light-3;
  border: 3px solid $black-light-3;
  border-radius: 10px;
  padding: 20px;
  transition: .2s;

  &:hover {
    border-color: $green;
  }

  .text {
    margin: auto;
  }
}