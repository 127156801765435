@import "core/scss/vars";

.subtask {
  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    .delete {
      opacity: 1;
    }
  }


  .circle {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid $empty;
    background-color: white;
    transition: all .2s;

    &:hover {
      border-color: $green;
    }

    .fillCircle {
      width: 100%;
      height: 100%;
      background-color: $green;
      border: 2px solid white;
      border-radius: 50%;
      opacity: 0;
      transition: all .1s;
    }
  }

  .text {
    background-color: $black-light-3;
    margin-left: 10px;
    width: 100%;
  }

  .delete {
    background-color: $black-light-3;
    border-radius: 100px;
    padding: 10px;
    opacity: 0;
    transition: all .2s;
    
    &:hover {
      background-color: $black-light-4;
    }
    
    img {
      width: 10px;
      height: 10px;
    }
  }
}

.completed {
  .circle {
    background-color: $green-light;

    .fillCircle {
      opacity: 1;
    }
  }

  .text {
    text-decoration: line-through;
  }
}