@import "../../../scss/vars";

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black_dark;
  display: flex;
  transition: all .2s;

  z-index: -1;
  opacity: 0;

  .content {
    margin: auto;
    background-color: $black-light-2;
    padding: 50px;
    border-radius: 20px;

    .title {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}

.visible {
  z-index: 3;
  opacity: 1;
}