@import "core/scss/vars";

.item {
  padding: 10px 15px;
  border-radius: 50px;
  border: 2px solid $empty;
  cursor: pointer;

  transition: all .2s;

  &:hover {
    background-color: $black-light-3;
    border-color: $black-light-3;
  }
}

.active {
  border-color: $green;

  &:hover {
    border-color: $green;
  }
}