@import "core/scss/vars";

.layout {
  display: flex;
  .leftBar {
    height: 100vh;
    border-right: 3px solid $black-light-2;
  }

  .content {
    height: 100vh;
    padding: 75px;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}