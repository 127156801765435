@import "core/scss/vars";

.button {
  padding: 12px;
  border-radius: 20px;
  border: 2px solid $empty;
  background-color: $empty;
  transition: all .2s;
}

.resume {
  border-color: $red;
  &:hover {
    background-color: $red;
  }
}

.complete {
  border-color: $green;
  &:hover {
    background-color: $green;
  }
}