@import "core/scss/vars";

.block {
  background-color: $black-light-2;
  padding: 20px;
  transition: all .2s;
  border-radius: 10px;

  &:hover {
    background-color: $black-light-3;
  }
}