@import "core/scss/vars";

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black_dark;
  display: flex;
  transition: all .2s;

  z-index: -1;
  opacity: 0;

  .content {
    position: relative;
    margin: auto;
    background-color: $black-light-2;
    padding: 50px;
    border-radius: 20px;

    .hint {
      margin-bottom: 10px;
    }

    .title {
      font-size: 32px;
      font-weight: 500;
      margin-bottom: 15px;
    }
  }
}

.visible {
  z-index: 3;
  opacity: 1;
}