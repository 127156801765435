@import "core/scss/vars";

.block {
  display: flex;
  flex-direction: column;

  width: fit-content;
  padding: 20px 30px 20px 0;
  background-color: $black-light-2;
  border-radius: 20px;

  .title {
    padding: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
  }
}