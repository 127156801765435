@import "core/scss/vars";

.button {
  background-color: $black-light-3;
  border-radius: 10px;
  min-width: 200px;
  padding: 20px 30px;

  font-size: 20px;
  transition: all .2s;

  &:hover {
    background-color: $black-light-4;
  }
}