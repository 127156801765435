@import "core/scss/vars";

.bar {
    width: 100px;
    display: flex;

    .completed {
        background-color: $green !important;
    }

    .item {
        background-color: #fff;
        height: 100%;

        &:first-child {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        &:last-child {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        &:nth-child(n + 2) {
            border-left: 1px solid $black-light-3;
        }
    }
}