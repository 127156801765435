@import "core/scss/vars";

.block {
  display: flex;
  gap: 20px;
  padding: 20px;

  .image {
    width: 50px;
    height: 50px;
    border-radius: 30px;
  }

  .right {
    display: flex;
    flex-direction: column;

    .hint {
      color: $black-light-5;
    }
    .project {
      font-size: 20px;
    }
  }
}