@import "core/scss/vars";

.user {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;

  .image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 40px;
  }
}