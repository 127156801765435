// ===========
// Font style
// ===========
$f-black: 'Gotham-Black', sans-serif;
$f-bold: 'Gotham-Bold', sans-serif;
$f-bold-italic: 'Gotham-BoldItalic', sans-serif;
$f-medium: 'Gotham-Medium', sans-serif;
$f-medium-italic: 'Gotham-MediumItalic', sans-serif;
$f-book: 'Gotham-Book', sans-serif;
$f-book-italic: 'Gotham-BookItalic', sans-serif;

// ===========
// Font size
// ===========
$fs-96: 96px;
$fs-64: 64px;
$fs-48: 48px;
$fs-40: 40px;
$fs-36: 36px;
$fs-32: 32px;
$fs-24: 24px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-15: 15px;
$fs-12: 12px;

// ===========
// Color
// ===========
$white: #FFF;
$black: #000;

$black-light-1: #0C0C0D;
$black-light-2: #18181B;
$black-light-3: #27272A;
$black-light-4: #3C3C3C;
$black-light-5: #92929A;

$gray-1: #9E9EA7;
$gray-2: #EBEBEB;

$empty: #00000000;
$black_dark: #000000A0;

$red: #D31818;
$red-dark: #2B181B;

$green: #00F2A6;
$green-dark: #162E29;
$green-light: #CCFCED;

$purple: #9747FF;