@import "core/scss/vars";

.block {
    padding: 40px !important;
    padding-bottom: 30px !important;
}

.wrapper {
    display: flex;
    flex-direction: column;

    .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        
        .input {
            padding: 15px 20px;
        }
    }
    
    .buttons {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        margin-left: auto;

        .back {
            background-color: $black-light-3;
            color: #fff;
            width: 100px;
            height: max-content;
            border: 2px solid $black-light-3;

            &:hover {
                background-color: $black-light-2;
            }
        }
    }
}