@font-face {
    font-family: "Gotham-Black";
    src: url("core/static/font/Gotham/Gotham-Black.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Gotham-Bold";
    src: url("core/static/font/Gotham/Gotham-Bold.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Gotham-BoldItalic";
    src: url("core/static/font/Gotham/Gotham-BoldItalic.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Gotham-Medium";
    src: url("core/static/font/Gotham/Gotham-Medium.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Gotham-MediumItalic";
    src: url("core/static/font/Gotham/Gotham-MediumItalic.ttf");
    font-display: swap;
}

@font-face {
    font-family: "Gotham-Book";
    src: url("core/static/font/Gotham/Gotham-Book.ttf");
    font-display: swap;
}
@font-face {
    font-family: "Gotham-BookItalic";
    src: url("core/static/font/Gotham/Gotham-BookItalic.ttf");
    font-display: swap;
}



/*==========================*/
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: #0C0C0D;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
    font-family: "Gotham Medium", sans-serif;
    font-size: 16px;
}


*::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #0C0C0D;
}

*::-webkit-scrollbar
{
    width: 5px;
    background-color: #0C0C0D !important;
}

*::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}


a {
    text-decoration: none;
}

img {
    -webkit-user-select: none; /* Отключение выделения для Chrome, Safari, и Opera */
    -moz-user-select: none; /* Отключение выделения для Firefox */
    -ms-user-select: none; /* Отключение выделения для Internet Explorer */
    user-select: none; /* Стандартный синтаксис */
    display: -webkit-box;
}

button {
    outline: none;
    border: none;
    -moz-appearance: none; /* Отключение стилей Firefox */
    -webkit-appearance: none; /* Отключение стилей WebKit (Chrome, Safari) */
    appearance: none; /* Отключение стилей для других браузеров */
}

input {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
}