@import "../../../scss/vars";

.block {
  display: flex;
  gap: 30px;

  height: 600px;

  .loadingWrapper {
    width: 400px;
  }
}