@import "../../../../../core/scss/vars";

.layout {
  max-width: 350px;
  width: 100%;
  height: 100%;
  padding: 10px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .user {
    display: flex;
    margin: 10px;
  }

  .block {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      margin-left: 20px;
      color: $black-light-5;
    }
  }

  .section {
    margin: 0 10px;
  }

  .logout {
    margin: auto 10px 20px;
  }
}