.left {
  width: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .top {
    .title {
      font-size: 48px;
      font-weight: 500;
    }

    .code {
      margin-top: 10px;
    }
  }
}