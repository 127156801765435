.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50px;
  height: 50px;
  animation: rotate 1s infinite linear;
}


// ANIMATION
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}