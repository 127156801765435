@import "core/scss/vars";

.block {
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;

  background-color: $black-light-3;
  border-radius: 20px;
  padding: 30px 20px;
  max-width: 330px;

  .items {
    display: flex;
    flex-direction: column;
  }

  .text {
    gap: 20px;
  }

  .users {
    gap: 10px
  }

}