@import 'core/scss/vars';

.block {
    display: flex;
    flex-direction: column;
    gap: 5px;
    
    //padding: 10px;
    padding: 6px 10px 2px 10px;
    background-color: $black-light-3;
    border-radius: 20px;

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        transition: all .2s;

        .title {
            font-size: 16px;
            font-weight: 500;
            background-color: $black-light-3;
            width: 100%;
        }

        .right {
            display: flex;
            gap: 10px;
            align-items: center;

            .arrow {
                display: flex;
                background-color: $black-light-3;
                border-radius: 100px;
                padding: 10px;
                transition: all .2s;

                &:hover {
                    background-color: $black-light-4;
                }

                img {
                    rotate: 180deg;
                    width: 15px;
                    height: 15px;
                    transition: all .2s;
                }
            }

            .delete {
                display: flex;
                background-color: $black-light-3;
                border-radius: 100px;
                padding: 10px;
                transition: all .2s;

                &:hover {
                    background-color: $black-light-4;
                }

                img {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    .subtasks {
        height: 0;
        overflow: hidden;
        transition: all .2s;

        .line {
            width: 100%;
            height: 1px;
            background-color: $black-light-4;
        }
    }
}

.open {
    padding-bottom: 10px;
    .top {
        .right {
            .arrow img {
                rotate: 0deg;
            }
        }
    }
    .subtasks {
        height: auto;
    }
}