@import "../../../../../../../../scss/vars";

.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  .item {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;

      .title {
        color: $black-light-5;
      }
      .button {
        color: $black-light-5;
        background-color: $empty;
        padding: 2px 7px;
        border-radius: 10px;
        transition: all .2s;

        &:hover {
          background-color: $black-light-4;
        }
      }
    }

    .line {
      width: 100%;
      height: 2px;
      background-color: $black-light-4;
      border-radius: 2px;
    }

    .list {
      .addUser {
        margin-top: 3px;
      }
    }
  }
}